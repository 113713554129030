import type { NextPage } from "next"
import React, { useContext, useEffect, useMemo } from "react"
import { useRouter } from "next/router"
import { Launch } from "../components/Atoms/Launch"
import { Grid, useTheme } from "@mui/material"
import { ActionTypes as UserActionTypes, UserContext } from "../contexts/user"
import { SiteNotificationsContext } from "../contexts/siteNotificationContext"
import { WafflesContext } from "../contexts/waffles"
import { getGoogleSecret } from "../utils/googleSecrets"
import { ICustomPageProps } from "./_app"

const LoginPage: NextPage<ICustomPageProps> = ({ captchaKey }) => {
  const { dispatch, context } = useContext(UserContext)
  const { siteNotificationsDispatch } = useContext(SiteNotificationsContext)
  const { wafflesDispatch } = useContext(WafflesContext)

  const router = useRouter()
  const theme = useTheme()

  useEffect(() => {
    if (captchaKey) {
      dispatch({
        type: UserActionTypes.SetCaptcha,
        payload: {
          captcha: captchaKey,
        },
      })
    }
  }, [captchaKey])

  if (context.isAuthenticated) {
    // useMemo used to prevent multiple calls to router push
    useMemo(() => router.push("/"), [])
    return <></>
  } else {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        sx={{ backgroundColor: theme.palette.primary.main, minHeight: "100vh" }}
      >
        <Grid item xl={4} lg={5} md={7} sm={10} xs={12} position="relative">
          <Launch
            dispatch={dispatch}
            siteNotificationDispatch={siteNotificationsDispatch}
            wafflesDispatch={wafflesDispatch}
          />
        </Grid>
      </Grid>
    )
  }
}

export async function getServerSideProps(): Promise<{
  props: ICustomPageProps
}> {
  const captchaKey = await getGoogleSecret("CLOUDFLARE_TURNSTILE_API_KEY")
  return {
    props: {
      captchaKey: captchaKey,
      isPublicRoute: true,
    },
  }
}

export default LoginPage
